.feed,
.user {
  width: calc(100% - 270px);
  position: fixed;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-areas: "trending trending" "posts communities";
  grid-template-rows: 224px;
  overflow-y: scroll;
  height: calc(100% - 48px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-child {
  width: 990px;
}
.feed-bottom {
  display: flex;
  justify-content: space-between;
}
.sr-r-r-communities-type {
  width: 310px;
  border-radius: 4px;
  border: 0.5px solid var(--greyColor4);
  background: var(--mainColor);
  margin-bottom: 16px;
  height: fit-content;
  margin-top: 48px;
  color: var(--mainFontColor);
}
