.SearchResults {
  width: calc(100% - 270px);
  height: calc(100% - 48px);
  position: fixed;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mainFontColor);
}
.search-results {
  width: 975px;
  height: 100%;
  margin-top: 24px;
}

.sr-nav {
  display: flex;
  margin-bottom: 24px;
}
.sr-n-button {
  height: 42px;
  padding: 0 20px;
  background: none;
  border: none;
  border-radius: 500px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
  color: var(--mainFontColor);
}
.sr-n-button:hover {
  background: var(--greyColor2);
}
.sr-n-button-chosen {
  background: var(--greyColor1);
  color: var(--mainFontColor);
}

.sr-query {
  display: flex;
}
.sr-q-button {
  padding: 4px 8px;
  background: none;
  border: none;
  border-radius: 500px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  margin-right: 8px;
}
.sr-q-button:hover {
  background: var(--greyColor6);
}

.sr-results {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.sr-r-left-nothing {
  width: 640px;
  height: 250px;
  background: var(--mainColor);
  border-radius: 4px;
  border: 0.5px solid var(--greyColor4);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.sr-r-l-n-top {
  height: 112px;
  color: var(--accentColor);
  border: 8px solid var(--accentColor);
  padding: 16px;
  border-radius: 12px;
}
.sr-r-l-n-middle {
  font-size: 20px;
  font-weight: 500;
}

.sr-r-right {
  width: 310px;
}
.sr-r-r-communities {
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid var(--greyColor4);
  background: var(--mainColor);
  margin-bottom: 16px;
  /* padding-top: 16px; */
}
.sr-r-r-name {
  font-weight: 500;
}
.nc-r-s-r-content {
  padding: 8px 16px;
}
.nc-r-s-r-content:hover {
  background: var(--greyColor6);
}
.nc-r-s-r-title {
  padding: 16px 16px;
  font-weight: 500;
}
.nc-r-s-r-content {
  font-weight: 500;
}

.comment-search {
  display: flex;
  width: 640px;
  gap: 16px;
  padding: 16px;
  background: var(--mainColor);
  color: var(--mainFontColor);
  border-radius: 4px;
  border: 0.5px solid var(--greyColor4);
  margin-bottom: 8px;
  margin-top: 16px;
}
