.udd-container {
  width: 210px;
  border: 0.5px solid var(--greyColor3);
  padding: auto;
  border-radius: 2px;
  position: absolute;
  top: 31px;
  left: -141px;
  z-index: 10000;
  background: var(--mainColor);
  color: var(--mainFontColor);
}

.udd-container2 {
  width: 210px;
  border: 0.5px solid var(--greyColor3);
  padding: auto;
  border-radius: 2px;
  position: absolute;
  top: 37px;
  left: -88px;
  z-index: 10000;
  background: var(--mainColor);
}

.udd-mode,
.udd-log,
.udd-profile,
.udd-community {
  height: 40px;
  width: 100%;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.udd-mode {
  margin-top: 10px;
}
.udd-moon,
.udd-box,
.udd-user,
.udd-comm {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.udd-mode:hover,
.udd-log:hover,
.udd-profile:hover,
.udd-community:hover {
  background: var(--accentColor);
  color: var(--mainColor);
}
