.redditlogo {
  height: 32px;
  width: 32px;
  margin: 8px 8px 8px 0;
  /* margin-left: 20px; */
}
.redditlogo2 {
  height: 18px;
  margin: 14px 0;
  margin-right: 32px;
}
.reddit-logo {
  height: 32px;
}

.navbar-container {
  z-index: 10;
  display: flex;
  position: fixed;
  /* overflow: hidden; */
  top: 0;
  height: 49px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--greyColor3);
  background: var(--mainColor);
}

.nc-left {
  display: flex;
  align-items: center;
}

.nc-right {
  width: 334px;
  display: flex;
  justify-content: space-between;
}

.home-button {
  width: 268px;
  height: 34px;
  background-color: bisque;

  /* margin-right: 50px; */
}
.nc-l-button {
  padding: 0;
  background: var(--mainColor);
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}
.nc-l-b-house {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.nc-l-b-text {
  margin: auto;
}

.nc-r-s-glass {
  height: 20px;
  width: 20px;
  color: #878a8c;
  margin: 10px 12px;
}

.nc-r-search {
  width: 645px;
  height: 40px;
  /* background-color: var(--greyColor1); */
  border-radius: 500px;
  /* border: var(--greyColor3) solid 0.25px; */
  display: flex;
  position: relative;
}
.nc-r-search:hover {
  background-color: var(--mainColor);
  border: solid 0.5px var(--accentColor);
}
.nc-r-search:focus-within {
  background-color: var(--mainColor);
  border: solid 0.5px var(--accentColor);
}
.nc-r-search-e {
  background: rgb(255, 210, 210);
}
.nc-r-search-drop {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.nc-r-s-input {
  margin: auto 0;
  width: 75%;
}
.nc-r-s-input > input {
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #878a8c;
  /* font-family: "IBM Plex Sans", Arial, sans-serif; */
}

.nc-r-signup {
  width: 118px;
  height: 30px;
  border: solid 1px var(--accentColor);
  color: var(--accentColor);
  border-radius: 500px;
  background-color: var(--mainColor);
  font-weight: 700;
  cursor: pointer;
}
.nc-r-signup:hover {
  background-color: #f6fafd;
}

.nc-r-login {
  width: 118px;
  height: 30px;
  border: solid 1px var(--accentColor);
  color: var(--mainColor);
  border-radius: 500px;
  background-color: var(--accentColor);
  font-weight: 700;
  cursor: pointer;
}
.nc-r-login:hover {
  background-color: var(--accentColor);
}

.nc-r-dropdown {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 70px;
  height: 32px;
  border: 1px solid var(--mainColor);
}

.nc-r-dropdown:hover {
  border: 1px solid var(--greyColor3);
  border-radius: 2px;
}

.nc-r-d-user {
  width: 20px;
  height: 20px;
  padding-right: 5px;
  color: var(--greyColor5);
}
.nc-r-d-user-pic {
  width: 28px;
  height: 28px;
  border-radius: 100%;
}

.nc-r-dropdown2 {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 38px;
  color: #878a8c;
  border: 1px solid var(--mainColor);
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  justify-self: flex-end;
}

.nc-r-dropdown2:hover {
  border: 1px solid var(--greyColor3);
  border-radius: 2px;
}
.nc-r-s-results-container {
  border: 1px solid var(--accentColor);
  border-top: none;
  background: var(--backColor);
  position: absolute;
  top: 38px;
  left: -1px;
  width: 100%;
  font-weight: 500;
  width: calc(100% + 2px);
  color: var(--mainFontColor);
}

.nc-r-s-results {
  width: 100%;
  font-weight: 500;
  margin-bottom: 2px;
  background: var(--mainColor);
  padding: 8px 16px;
}
.nc-r-s-results-2 {
  width: 100%;
  font-weight: 300;
  background: var(--mainColor);
  padding: 8px 16px;
  display: block;
}
.nc-r-s-r-content {
  display: flex;
  padding: 8px 0;
}
.nc-r-s-r-c-image {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  margin-right: 8px;
  align-self: center;
  justify-self: center;
}
.nc-r-s-r-c-name {
  font-size: 14px;
}
.nc-r-s-r-c-n-2 {
  font-weight: 400;
  font-size: 12px;
  color: #7c7c7c;
}
