@import url("https://fonts.googleapis.com/css2?family=Flow+Circular&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --comTheme2: none;
  --comTheme1: none;
  --tempTheme2: none;
  --tempTheme1: none;

  --mainColor: none;
  --greyColor1: none;
  --greyColor2: none;
  --greyColor3: none;
  --greyColor4: none;
  --greyColor5: none;
  --greyColor6: none;
  --backColor: none;
  --accentColor: none;

  --mainFontColor: none;
  --secFontColor: rgba(117, 117, 117, 1);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "IBM Plex Sans", Arial, sans-serif;
}

.App {
  height: 100vh;
  background: var(--backColor);
}

body::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
