.trending {
  height: 224px;
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: trending;
  /* margin-top: 16px; */
}
.trending-title {
  font-size: 14px;
}
.tending-feed {
  display: flex;
  justify-content: space-between;
  color: var(--mainColor);
}
.tending-article {
  width: 238px;
  height: 178px;
  background: black;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.t-a-title {
  font-size: 18px;
}
.t-a-description {
  width: 214px;
  height: 40px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.t-a-community {
  font-size: 12px;
  display: flex;
}
.t-a-c-logo {
  height: 16px;
  width: 16px;
  background: var(--mainColor);
  border-radius: 100%;
  margin-right: 4px;
}
