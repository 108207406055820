.posts {
  width: 640px;
  max-height: 100%;
  background: var(--backColor);
  grid-area: posts;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  color: var(--mainFontColor);
}
.posts-title {
  font-size: 14px;
}
.posts-post {
  background: var(--mainColor);
  border-radius: 4px;
  border: var(--greyColor4) 0.5px solid;
  max-height: 676px;
  width: 640px;
  display: flex;
  text-decoration: none;
  color: var(--mainFontColor);
}
.posts-post:hover {
  border: #898989 0.5px solid;
  text-decoration: none;
  cursor: pointer;
}

.posts-sorter {
  width: 640px;
  height: 61px;
  border: var(--greyColor4) 0.5px solid;
  border-radius: 4px;
  background: var(--mainColor);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secFontColor);
}
.p-s-option {
  height: 32px;
  width: auto;

  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; */

  background: var(--mainColor);
  color: inherit;
  border: none;
  padding: 6px 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  border-radius: 500px;
}
/* color: var(--postColor); */
.p-s-chosen {
  color: var(--accentColor);
  background: var(--greyColor1);
  padding: 6px 8px;
}
.p-s-new,
.p-s-top,
.p-s-format {
  color: #878a8c;
  padding: 6px 8px;
}
.p-s-format {
  margin-left: auto;
}
.p-s-option:hover {
  background: var(--greyColor2);
}
.ps-o-new-logo,
.ps-o-top-logo {
  background: none;
}

.pp-left {
  width: 40px;
  height: 100%;
  background: var(--greyColor7);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pp-l-up {
  margin-top: 10px;
}
.pp-l-up,
.pp-l-down {
  height: 21px;
  width: 21px;
}
.pp-l-up-logo,
.pp-l-down-logo {
  height: 20px;
  width: 20px;
  margin: auto 0;
  background: var(--greyColor7);
  color: var(--mainFontColor);
}
.pp-l-up-logo-voted {
  color: #cc3800;
}
.pp-l-down-logo-voted {
  color: #5a74cc;
}
.pp-l-up-logo:hover {
  color: #cc3800;
}
.pp-l-down-logo:hover {
  color: #5a74cc;
}
.pp-l-count {
  font-size: 12px;
  margin: 4px 0;
}
.pp-middle {
  width: 100%;
  padding: 8px;
}

.pp-m-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.pp-m-top-left {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.pp-m-t-l-logo {
  height: 20px;
  width: 20px;
  margin-right: 4px;
  border-radius: 100%;
}
.pp-m-t-l-community {
  font-weight: bolder;
}
.pp-m-t-l-user {
  color: #787c7e;
  margin-left: 10px;
}
.pp-m-t-r-button {
  width: 55px;
  height: 24px;
  border-radius: 500px;
  border: none;
  color: var(--mainColor);
  background: var(--accentColor);
  cursor: pointer;
}

.pp-m-m-title {
  font-size: 18px;
}
/* 598,512 */
.pp-m-m-link {
  width: 172px;
  height: 15px;
  margin: 4px 0;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--postColor);
}
.pp-m-m-photo {
  width: 100%;
  height: 100%;
  max-height: 512px;
  max-width: 598px;
}

.pp-m-bottom {
  width: 100%;
  height: 36px;

  font-size: 12px;
  display: flex;
  gap: 4px;
}
.pp-m-bottom > button {
  height: 36px;
  background: none;
  color: #878a8c;
  border: none;
  padding: 0 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pp-m-b-button {
  height: 36px;
  background: none;
  color: #878a8c;
  border: none;
  padding: 0 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pp-m-b-button:hover {
  background-color: #e8e8e8;
}
.pp-m-b-delete {
  height: 36px;
  background: none;
  color: red;
  border: none;
  padding: 0 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pp-m-b-delete:hover {
  background: rgb(255, 210, 210);
}
.pp-m-b-edit {
  height: 36px;
  background: none;
  color: var(--postColor);
  border: none;
  padding: 0 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pp-m-b-edit:hover {
  background: #c2d4e4;
}

.pp-m-b-o-button {
  width: 20px;
  height: 20px;
}

.pp-m-m-video {
  width: 100%;
  height: 100%;
  max-height: 512px;
  max-width: 598px;
}

.pp-m-m-left-link {
  width: 422px;
}

.p-m-m-right {
  width: 100%;
}
.pp-m-middle-link {
  display: flex;
}
.p-m-m-r-link {
  height: 100px;
  width: 144px;
  border: var(--accentColor) 0.5px solid;
  position: relative;
  border-radius: 4px;
}
.p-m-m-r-link-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--accentColor);
}
.p-m-m-r-l-logo-container {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--accentColor);
  height: 20px;
  width: 20px;
  border-top-left-radius: 4px;
}
.p-m-m-r-l-logo {
  height: 14px;
  width: 14px;
  margin: 3px;
  color: var(--mainColor);
}

.pp-text {
  max-height: 397px;
}
.pp-m-m-content {
  padding-top: 8px;
  max-height: 265px;
  overflow-y: hidden;
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.f-h-logo {
  width: 20px;
  height: 20px;
}
.empty-post {
  width: 100%;
  height: 14px;
}
